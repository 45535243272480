import { Row, Col, Container, Stack, Image } from "react-bootstrap";
import { PoSidebar } from "../PO_Sidebar";
import { Headings } from "../../components/Headings";
import { UpcomingEventTable } from "./UpcomingEventTable";
import { ApparatusStatusTable } from "./ApparatusStatusTable";
import { PendingQualificationTable } from "./PendingQualificationExpireTable";
import { UnavailableStaffTable } from "./UnavailableStaffTable";
import { useEffect, useState } from "react";
import { Loader } from "../../components/Loader";
import moment, { unix } from "moment";
import { Avatar } from "antd";
import { AvatarNew } from "../../components/AvatarNew";
// import { unavailableStaff } from "../../helper/Helper";
import { getDashboardQualificationAPI, toDayUnavailableUsersAPI } from "../../api_services/Apiservices";

export const Dashboard = () => {

    const [loder, setLoder] = useState(false);
    const [myData, setMyData] = useState(JSON.parse(localStorage.getItem('userData')));
    const [pendingQualification, setPendingQualification] = useState([{}]);

    const [unavailableStaff, setUnavailableStaff] = useState([]);

    // const pendingQualification = [
    //     { name: 'Jackson', qualification: 'CPR I', expiration: '10/15/2024' },
    //     { name: 'Jackson', qualification: 'CPR I', expiration: '10/15/2024' }
    // ];

    const [currentTime, setCurrentTime] = useState(moment().format('LL'));

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTime(moment().format('LL'));
        }, 1000);
        return () => clearInterval(interval);
    }, []);



    const qualificationHandler = async () => {

        const next60DaysTimestamp = moment().add(60, 'days').unix();
        const resp = await getDashboardQualificationAPI(next60DaysTimestamp);
        if (resp && resp.success) {
            let pre = [];
            const data = resp.data;
            data.forEach((e) => {
                pre.push({
                    "name": e?.user_id?.first_name + " " + e?.user_id?.last_name,
                    "qualification": e?.qualifications_id?.name,
                    "type": e?.qualifications_id?.type,
                    "expiration": e?.exp_date ? moment.unix(e.exp_date).format('MM-DD-YYYY') : ""
                })

            });
            setPendingQualification(pre)
        } else {
            setPendingQualification([])
        }
    }


    const getToDayUnavailability = async () => {
        setLoder(true);
        const dates = moment().format('YYYY-MM-DD');
        const resp = await toDayUnavailableUsersAPI(dates);
        if (resp && resp.success) {
            setLoder(false);
            const prefdata = resp.data;
            const data = [];
            if (prefdata && prefdata.length > 0) {
                prefdata.forEach((e) => {
                    data.push({
                        name: e?.first_name + " " + e?.last_name,
                        mobile: e?.mobile_no,
                        email: e?.email,
                        role: e?.role_name,
                        position: e?.position
                    });
                });
            }
            setUnavailableStaff(data);
        }
        setLoder(false);
    }

    useEffect(() => {
        qualificationHandler();
        getToDayUnavailability();
        return;
    }, [])


    const [apStatus, setApStatus] = useState(false);
    return (
        <>
            <Loader show={loder} />
            <div className="dashboard">
                <Container fluid>
                    <Row>
                        <Col md={3}>
                            <PoSidebar />
                        </Col>
                        <Col md={9}>
                            <Stack className='pt-md-1 px-1 mt-4' direction='horizontal' gap={2} style={{
                                justifyContent: 'space-between'
                            }}>
                                {myData && myData?.isGuest === false ?
                                    <Stack direction="horizontal" gap={2} className="align-items-center">
                                        <Image
                                            src={myData?.image ? myData.image : "assets/images/avatar.png"}
                                            style={{ maxWidth: "50px" }}
                                            className='Avatar_img'
                                            roundedCircle
                                            alt=''
                                        />
                                        <span style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                                            <h4> {myData?.account_name}</h4>
                                        </span>
                                    </Stack>

                                    :
                                    <Stack direction="horizontal" gap={2} className="align-items-center">
                                        <Image
                                            src={myData?.guestData?.image ? myData?.guestData?.image : "assets/images/avatar.png"}
                                            style={{ maxWidth: "50px" }}
                                            className='Avatar_img'
                                            roundedCircle
                                            alt=''
                                        />
                                        <span style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                                            <h4> {myData?.guestData?.first_name + " " + myData?.guestData?.last_name}</h4>
                                        </span>
                                    </Stack>
                                    // <Stack direction='vertical' gap={0}>
                                    //     <AvatarNew img={myData?.guestData?.image} LinkLabel={myData?.guestData?.first_name + " " + myData?.guestData?.last_name} />
                                    // </Stack>
                                }

                                <Stack direction='horizontal' gap={0}>
                                    <h5>  {currentTime}</h5>
                                </Stack>
                            </Stack>

                            <hr />
                            <Row >
                                <Col md={12} className="mt-2 mb-4">
                                    <div className="myCustomHeader mb-3">
                                        <h5>Unavailable Staff Today</h5>
                                    </div>
                                    {unavailableStaff && unavailableStaff.length > 0 ?
                                        <UnavailableStaffTable data={unavailableStaff} />
                                        :
                                        <div style={{ textAlign: 'center', marginTop: "30px" }}>
                                            <h5>No record found.</h5>
                                        </div>
                                    }
                                </Col>
                                <Col md={12} className="mt-3 mb-4">
                                    <div className="myCustomHeader mb-3">
                                        <h5>Apparatus Status</h5>
                                    </div>
                                    <ApparatusStatusTable setLoder={setLoder} setApStatus={setApStatus} />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12} className="mt-4 mb-4">
                                    <div className="myCustomHeader mb-3">
                                        <h5>Upcoming Event Information</h5>
                                    </div>
                                    <UpcomingEventTable setLoder={setLoder} />
                                </Col>

                                <Col md={12} className="mt-4 mb-4">
                                    <div className="myCustomHeader mb-3">
                                        <h5>Pending Qualifications Expirations </h5>
                                    </div>
                                    <PendingQualificationTable data={pendingQualification} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}