import { Table } from "react-bootstrap";
import { getApparatus_API } from "../../api_services/Apiservices";
import { useEffect, useState } from "react";
import { sortEventName } from "../../helper/Helper";

export const ApparatusStatusTable = ({setLoder }) => {
    const [trData, setTrData] = useState([]);

    const getdata = async (order = "") => {
        setLoder(true);
        const resp = await getApparatus_API(order);
        if (resp && resp.success) {
            setLoder(false);
            let data = resp.data;
            data = data.map((e) => (
                {
                    "name": e.name,
                    "type": e.type_Id && e.type_Id.type,               
                    "item_status": e.item_status === "in_service" ? "In Service" : "Out of Service",
                    "allData": e
                }));

            const sortData = sortEventName(data, true);
            setTrData(sortData);
        }
        setLoder(false);
    }
    useEffect(() => { getdata(); }, [])


    return (
        <div className="MainTable subMain">
            <Table className="table table-hover" style={{ margin: '0px', padding: "0px" }} responsive>
                <thead >
                    <tr className="text-center">
                        <th>NAME</th>
                        <th>TYPE</th>
                        <th>STATUS</th>
                    </tr>
                </thead>
                <tbody>
                        {trData && sortEventName(trData, true).map((items, index) => {
                            return (
                                <tr key={index}>
                                    <td className="text-center" style={{ textTransform: 'capitalize' }} >{items.name}</td>
                                    <td className="text-center" style={{ textTransform: 'capitalize' }}>{items.type}</td>
                                    <td className="text-center" ><span style={{color:'black',fontWeight:"bold"}} className={`px-2 py-1 rounded ${items.item_status === 'In Service' ? 'text-darksuccess bg-lightsuccess' : 'text-darkdanger bg-lightdanger'}`} >{items.item_status}</span></td>
                                </tr>
                            );
                        })}
                    </tbody>
            </Table>
            {/* <div className="subMain">
                <Table className="table table-hover" style={{ margin: '0px', padding: "0px" }} responsive>
                   
                </Table>
            </div> */}
        </div>
    );
} 