import { Table } from "react-bootstrap";
import { sortEventName } from "../../helper/Helper";

export const PendingQualificationTable = ({ data }) => {
    return (
        <div className="MainTable subMain">
            <Table className="table table-hover" style={{ margin: '0px', padding: "0px" }} responsive>
                <thead>
                    <tr>
                        <th>NAME</th>
                        <th>QUALIFICATION</th>
                        <th>EXPIRATION DATE</th>
                    </tr>
                </thead>
                <tbody>
                        {data && sortEventName(data, true).map((items, index) => (
                            <tr key={index}>
                                <td style={{ textTransform: 'capitalize' }}>{items.name}</td>
                                <td style={{ textTransform: 'capitalize' }}>{items.qualification}</td>
                                <td style={{ textTransform: 'capitalize' }}>{items.expiration}</td>
                            </tr>
                        ))}
                    </tbody>
            </Table>
            {/* <div className="subMain">
                <Table className="table table-hover" style={{ margin: '0px', padding: "0px" }} responsive>
                   
                </Table>
            </div> */}
            {/* <TablePagination pagination={pagination} pageHanlder={pageHanlder} /> */}
        </div>
    );
}